@use '../../assets/styles/colors';
@use '../../assets/styles/mixins';

.progress-bar {
  position: relative;
  margin: 0 4px;
  padding: 1px;
  border-width: 1px;
  border-style: solid;
  border-image: colors.$gradient-dark-gold 1;
  border-left-width: 0;
  border-right-width: 0;

  &::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 6px;
    height: 6px;
    border-width: 1px;
    border-style: solid;
    border-image: colors.$gradient-dark-gold 1;
  }

  &::before {
    left: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    border-top-width: 0;
    border-right-width: 0;
  }

  &::after {
    right: 0;
    transform: translate(50%, -50%) rotate(45deg);
    border-bottom-width: 0;
    border-left-width: 0;
  }

  &__current-position {
    position: relative;
    max-width: 100%;
    height: 4px;
    background-image: colors.$gradient-progress-bar;
    transition: 0.3s ease width;

    &::before {
      @include mixins.progress-bar-glow;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
