@use '../../assets/styles/colors';
@use '../../assets/styles/mixins';

.character-card {
  position: relative;
  width: 184px;
  background-color: transparent;
  border-radius: 5px;
  border: none;

  &__rounded-frame {
    .rounded-frame__content {
      padding: 5px;
    }
  }

  &__rectangle-frame {
    display: flex;
    padding: 0;
  }

  &__media-wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__selected-icon {
    position: absolute;
    top: 4px;
    right: 1px;
  }

  &__info {
    @include mixins.label-text-medium;
    position: absolute;
    bottom: -1px;
    left: -1px;
    width: calc(100% + 2px);
    text-shadow: colors.$text-shadow;

    &-frame {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 6px;
      padding: 4px;
      background-color: colors.$black-60;
    }

    &-block {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 2px;
    }

    &-label {
      color: colors.$text-white;
    }

    &-icon path {
      fill: colors.$text-white;
    }

    &-value {
      color: colors.$text-gold;
    }
  }
}

button.character-card:not([disabled]) {
  cursor: pointer;

  &:hover {
    @include mixins.bright-gold-glow;
  }

  &:active {
    @include mixins.bright-gold-light-glow;
  }
}

.character-card--selected {
  @include mixins.bright-gold-light-glow;
}

.character-card--inactive {
  .character-card__media-wrapper {
    height: 172px;
  }
}

.character-card--portrait {
  .character-card__info {
    @include mixins.label-text-xs;
  }
}

.character-card--landscape {
  width: 132px;

  .character-card__info {
    @include mixins.label-text-xs;
  }
}

.character-card--inactive.character-card--landscape {
  .character-card__media-wrapper {
    height: 120px;
  }
}
