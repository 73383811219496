@use '../../assets/styles/colors';
@use '../../assets/styles/mixins';

@function getGradient($color){
  @return linear-gradient(90deg, rgba($color, 0) 3.96%, $color 47.4%, rgba($color, 0) 94.9%);
}

.rarity-badge {
  @include mixins.label-text-xxs;
  padding: 1px 4px;
  text-align: center;
  color: colors.$text-white;
  text-shadow: colors.$text-shadow;
}

.rarity-badge--common {
  background-image: getGradient(colors.$common-rarity);
}
.rarity-badge--rare {
  background-image: getGradient(colors.$rare-rarity);
}
.rarity-badge--epic {
  background-image: getGradient(colors.$epic-rarity);
}
.rarity-badge--legendary {
  background-image: getGradient(colors.$legendary-rarity);
}
.rarity-badge--mythic {
  background-image: getGradient(colors.$mythic-rarity);
}
