@use '../../../../../assets/styles/colors';
@use '../../../../../assets/styles/mixins';

.dojo-modal {
  &__container {
    display: grid;
    align-items: center;
    grid-template-columns: 200px auto;
    column-gap: 8px;
  }

  &__characters-and-slots {
    overflow: hidden;

    &-wrapper {
      padding: 0 3px;
      border: 1px solid colors.$container-stroke-brown;
      background-color: colors.$black-60;
      border-radius: 8px;
      box-shadow: colors.$container-inner-shadow;
    }
  }

  &__characters {
    position: relative;
    margin-bottom: 4px;

    &-btn {
      align-self: center;
      width: max-content;
    }

    &-no-nfts {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 8px;
      padding: 30px 10px;

      &-label {
        @include mixins.body-xs;
        color: colors.$text-gold;
      }
    }

    &-toast.toast {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  &__slots {
    display: flex;
    column-gap: 8px;

    &-unlocked-slot {
      &-button {
        min-width: 110px;
      }
    }
  }
}

.dojo-modal--portrait {
  .modal__content-main {
    justify-content: flex-start;
  }

  .dojo-modal {
    &__container {
      align-items: initial;
      grid-template-columns: initial;
      column-gap: 0;
      row-gap: 8px;
    }

    &__media {
      max-width: 120px;
      margin: 0 auto;
    }

    &__characters {
      margin-bottom: 8px;
    }
  }
}
