.nfts-scrollable-container {
  margin-bottom: 8px;
  padding: 8px 0;
  overflow-x: auto;

  &__list {
    display: flex;
    justify-content: center;
    min-width: 100%;
    width: fit-content;
    column-gap: 16px;
    padding: 0 5px;
  }
}
