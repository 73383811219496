@use '../../assets/styles/colors';

@keyframes lightBackgroundMoving {
  0%, 10% { top: -50%; }
  90%, 100% { top: 300%; }
}

.rectangle-frame {
  position: relative;
  padding: 6px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: colors.$container-stroke-brown;

  &__corner {
    position: absolute;
    width: 1px;
    height: 6px;
    background-color: colors.$container-stroke-brown;

    &--top-left {
      top: -1px;
      left: 1px;
      transform: rotate(45deg);
    }

    &--top-right {
      top: -1px;
      right: 1px;
      transform: rotate(-45deg);
    }

    &--bottom-left {
      bottom: -1px;
      left: 1px;
      transform: rotate(-45deg);
  }

    &--bottom-right {
      bottom: -1px;
      right: 1px;
      transform: rotate(45deg);
    }
  }

  &__light-animation {
    position: absolute;
    bottom: 100%;
    left: -50px;
    height: 70px;
    width: 200%;
    filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.03);
    transform: rotate(-38deg);
    transform-origin: top left;
    animation: lightBackgroundMoving 1.8s infinite linear;
  }
}

.rectangle-frame--dashed {
  border-style: dashed;
}

.rectangle-frame--purple {
  border-color: colors.$container-stroke-purple;

  .rectangle-frame__corner {
    background-color: colors.$container-stroke-purple;
  }
}
