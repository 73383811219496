.buttons-container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  column-gap: 14px;
  margin: 0 auto;
  max-width: max-content;

  &--column {
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
    row-gap: 14px;
    width: 100%;
    max-width: initial;

    // Moves the last button at the top
    & > *:last-child {
      order: -1;
    }
  }
}
