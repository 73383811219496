@use '../../assets/styles/mixins';
@use '../../assets/styles/colors';

.login-view {
  padding: 14px;
  height: 100vh;

  &__rounded-frame {
    height: 100%;
  }

  &__rectangle-frame {
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__logo {
    max-width: 122px;
    margin-bottom: 16px;
  }

  &__title {
    @include mixins.label-text-medium();
    margin-bottom: 16px;
    color: colors.$text-gold-stroke;
  }

  &__buttons {
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 6px;
  }
}
