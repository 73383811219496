@use '../../../../assets/styles/colors';
@use '../../../../assets/styles/mixins';

.mine-building {
  position: relative;
  max-width: 168px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__location-banner.location-banner {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 300px;
  }

  &__modal {
    &-subtitle {
      @include mixins.body-small();
      margin-bottom: 8px;
      text-align: center;
      color: colors.$text-gold;
    }

    &-nfts-container {
      margin-bottom: 8px;
      padding: 8px 0;
      overflow-x: auto;
    }

    &-nfts {
      display: flex;
      justify-content: center;
      min-width: 100%;
      width: fit-content;
      column-gap: 16px;
      padding: 0 5px;
    }
  }
}
