@use '../../assets/styles/colors';
@use '../../assets/styles/mixins';

$corners: polygon(3% 0, 97% 0, 100% 50%, 97% 100%, 3% 100%, 0% 50%);

.location-banner {
  @include mixins.label-text-small();
  position: relative;
  width: 137px;
  padding: 3px 4px 5px;
  text-align: center;
  background-color: #3D1A52;
  color: colors.$text-gold;
  text-shadow: colors.$text-shadow;
  clip-path: $corners;

  &__glowing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 46.67%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.2;
  }

  &__background {
    position: absolute;
    top: 0;
    height: 100%;
    clip-path: $corners;

    &--lighter {
      left: 4px;
      width: calc(100% - 8px);
      background-color: rgba(81, 34, 109, 0.8);
    }

    &--lightest {
      left: 8px;
      width: calc(100% - 16px);
      background-color: #521F71;
    }
  }

  &__reflection {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 46.67%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.2;

    &--top {
      top: 3px;
    }

    &--bottom {
      bottom: 3px;
    }
  }

  &__children {
    position: relative;
  }
}

.location-banner--large {
  @include mixins.label-text-large();
  width: 160px;
}

.location-banner--disabled {
  color: colors.$text-white;
  text-shadow: colors.$text-shadow;
  background-color: rgba(48, 44, 51, 0.4);

  .location-banner__background {
    &--lighter {
      background-color: rgba(124, 116, 129, 0.8);
    }

    &--lightest {
      background-color: rgba(177, 177, 177, 0.6);
    }
  }
}
