@use '../../assets/styles/colors';
@use '../../assets/styles/mixins';

.toast {
  @include mixins.button-label-small;
  position: relative;
  display: inline-block;
  padding: 3px 16px 4px;
  text-align: center;
  color: colors.$text-white;
  background-image: linear-gradient(90deg, rgba(202, 166, 93, 0) 0%, rgba(202, 166, 93, 0.88) 49.36%, rgba(202, 166, 93, 0) 100%);
  text-shadow: colors.$text-shadow;
  filter: drop-shadow(colors.$toast-blur);

  &::before, &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 32px);
    height: 1px;
    background-image: colors.$container-stroke-white-divider;
    opacity: 0.2;
  }

  &::before {
    top: 1px;
  }

  &::after {
    bottom: 1px;
  }
}

.toast--warning {
  background-image: linear-gradient(90deg, rgba(255, 89, 89, 0) 3.96%, rgba(255, 89, 89, 0.61) 47.4%, rgba(255, 89, 89, 0) 94.9%);
}

.toast--large {
  @include mixins.button-label-medium;
  text-transform: uppercase;
}

.toast-group {
  position: fixed;
  bottom: 12px;
  right: 12px;
  display: flex;
  flex-direction: column-reverse;
  row-gap: 10px;
}
