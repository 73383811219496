.main-view {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;
    background-image: url('../../assets/images/map-large.png');
    background-position: center;
    background-repeat: no-repeat;
  }

  &__dojo-building {
    margin-bottom: 90px;
    transform: translateX(62px);

    &--portrait {
      transform: translateX(0px);
    }
  }
}
