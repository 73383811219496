@use './assets/styles/colors';
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@500;600;700;800&family=Roboto:ital,wght@0,100;1,100&display=swap');
$default-font-family: 'Exo 2', sans-serif;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  line-height: 1.2;
  font-family: $default-font-family;
  background-color: colors.$black;
  color: colors.$text-white;
}

// Resets default font family for elements should be reset specifically
input, input::placeholder, button {
  font-family: $default-font-family;
}

img, svg {
  max-width: 100%;
  height: auto;
}
