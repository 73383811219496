@use '../../assets/styles/colors';
@use '../../assets/styles/mixins';

$padding: 2px;
$border-radius: 2px;

.button {
  @include mixins.button-label-small();
  position: relative;
  display: flex;
  padding: $padding;
  background: colors.$gradient-white-gold;
  border: none;
  border-radius: $border-radius;
  color: colors.$text-white;
  text-transform: uppercase;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - $padding);
    height: calc(100% - $padding);
    border: 1px solid colors.$black;
    border-radius: $border-radius;
  }

  &:hover {
    @include mixins.bright-gold-glow();
  }

  &:active {
    @include mixins.bright-gold-light-glow();
  }

  &__reflection {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - $padding * 2);
    height: 2px;
    overflow: hidden;
    opacity: 0.36;

    &::before,
    &::after {
      content: '';
      width: 3px;
      height: 2px;
    }

    &--upper {
      top: $padding;
      left: $padding;
      background-color: colors.$white;
    }

    &--lower {
      bottom: $padding;
      left: $padding;
      background-color: #9BDB69;
    }
  }

  &__outer-frame {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 2px 3px;
  }

  &__text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 3px 6px;
    text-shadow: colors.$text-shadow;
  }

  &__icon {
    display: flex;
    margin-right: 5px;
    width: 22px;
    height: 22px;
  }
}

.button--primary {
  .button {
    &__outer-frame {
      background: linear-gradient(180deg, #77C22C 0%, #5F8B00 100%);
    }

    &__reflection {
      &--upper {
        &::before {
          background: linear-gradient(27deg, #76BF29 0%, #76BF29 50%, transparent 50%, transparent 100%);
        }

        &::after {
          background: linear-gradient(-27deg, #76BF29 0%, #76BF29 50%, transparent 50%, transparent 100%);
        }
      }

      &--lower {
        &::before {
          background: linear-gradient(152deg, #619004 0%, #619004 50%, transparent 50%, transparent 100%);
        }

        &::after {
          background: linear-gradient(-152deg, #619004 0%, #619004 50%, transparent 50%, transparent 100%);
        }
      }
    }

    &__text-container {
      background: linear-gradient(180deg, #77DC54 0%, #BCDF30 100%);
      box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.6);
    }
  }
}

.button--secondary {
  .button {
    &__outer-frame {
      background: linear-gradient(180deg, #DFBB73 0%, #795B2F 100%);
    }

    &__reflection {
      &--upper {
        &::before {
          background: linear-gradient(27deg, #D7B46E 0%, #D7B46E 50%, transparent 50%, transparent 100%);
        }

        &::after {
          background: linear-gradient(-27deg, #D7B46E 0%, #D7B46E 50%, transparent 50%, transparent 100%);
        }
      }

      &--lower {
        background-color: #B89144;

        &::before {
          background: linear-gradient(152deg, #826436 0%, #826436 50%, transparent 50%, transparent 100%);
        }

        &::after {
          background: linear-gradient(-152deg, #826436 0%, #826436 50%, transparent 50%, transparent 100%);
        }
      }
    }

    &__text-container {
      background: linear-gradient(353.37deg, #C29F57 5.04%, #A47926 94.67%);
      box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.3);
    }
  }
}

.button--tertiary {
  .button {
    &__outer-frame {
      background: linear-gradient(180deg, #7421A7 0%, #6F188D 100%);
    }

    &__reflection {
      &--upper {
        &::before {
          background: linear-gradient(27deg, #7421A5 0%, #7421A5 50%, transparent 50%, transparent 100%);
        }

        &::after {
          background: linear-gradient(-27deg, #7421A5 0%, #7421A5 50%, transparent 50%, transparent 100%);
        }
      }

      &--lower {
        background-color: #260E2C;

        &::before {
          background: linear-gradient(152deg, #701990 0%, #701990 50%, transparent 50%, transparent 100%);
        }

        &::after {
          background: linear-gradient(-152deg, #701990 0%, #701990 50%, transparent 50%, transparent 100%);
        }
      }
    }

    &__text-container {
      background: linear-gradient(180deg, #8437B3 0%, #7C2CAD 49.27%, #9445C5 100%);
      box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.15);
    }
  }
}

.button--ghost {
  .button {
    &__outer-frame {
      background: linear-gradient(180deg, #DCDCDC 0%, #AAAAAA 100%);
    }

    &__reflection {
      &--upper {
        &::before {
          background: linear-gradient(27deg, #DBDBDB 0%, #DBDBDB 50%, transparent 50%, transparent 100%);
        }

        &::after {
          background: linear-gradient(-27deg, #DBDBDB 0%, #DBDBDB 50%, transparent 50%, transparent 100%);
        }
      }

      &--lower {
        background-color: #9A9A9A;

        &::before {
          background: linear-gradient(152deg, #ABABAB 0%, #ABABAB 50%, transparent 50%, transparent 100%);
        }

        &::after {
          background: linear-gradient(-152deg, #ABABAB 0%, #ABABAB 50%, transparent 50%, transparent 100%);
        }
      }
    }

    &__text-container {
      background: linear-gradient(130.98deg, #BDBDBD 19.14%, #E4E4E4 77.32%);
      box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.15);
    }
  }
}

.button[disabled] {
  background: colors.$white-60;
  cursor: default;

  &:hover {
    box-shadow: none;
    background: colors.$white-60;
  }

  &:active {
    box-shadow: none;
    background: colors.$white-60;
  }

  .button {
    &__reflection {
      opacity: 1;

      &--upper {
        background-color: rgba(235, 235, 235, 0.6);

        &::before {
          background: linear-gradient(27deg, #848484 0%, #848484 50%, transparent 50%, transparent 100%);
        }

        &::after {
          background: linear-gradient(-27deg, #848484 0%, #848484 50%, transparent 50%, transparent 100%);
        }
      }

      &--lower {
        background-color: rgba(154, 154, 154, 0.6);

        &::before {
          background: linear-gradient(152deg, #676767 0%, #676767 50%, transparent 50%, transparent 100%);
        }

        &::after {
          background: linear-gradient(-152deg, #676767 0%, #676767 50%, transparent 50%, transparent 100%);
        }
      }
    }

    &__outer-frame {
      background: linear-gradient(180deg, rgba(220, 220, 220, 0.6) 0%, rgba(170, 170, 170, 0.6) 100%);
    }

    &__text-container {
      background: linear-gradient(130.98deg, rgba(189, 189, 189, 0.3) 19.14%, rgba(228, 228, 228, 0.3) 77.32%);
      box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.15);
    }
  }
}

.button--size-small {
  @include mixins.button-label-xs();
  text-transform: initial;
}
