@use '../../../../../../assets/styles/colors';
@use '../../../../../../assets/styles/mixins';

.staked-character-in-dojo {
  &__progress-xp {
    &-title {
      @include mixins.label-text-small;
      text-align: center;
      margin-bottom: 2px;
      color: colors.$text-gold;
    }
  }
}
