@use '../../assets/styles/colors';

$border-radius: 5px;

.rounded-frame {
  position: relative;
  margin: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: colors.$gold-dark;
  border-radius: $border-radius;

  &::before {
    content: '';
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 16px);
    height: calc(100% + 6px);
    border-width: 1px;
    border-style: solid;
    border-color: colors.$gold-dark;
    border-radius: $border-radius;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -3px;
    transform: translateY(-50%);
    width: calc(100% + 6px);
    height: calc(100% - 16px);
    border-width: 1px;
    border-style: solid;
    border-color: colors.$gold-dark;
    border-radius: $border-radius;
  }

  &__corner {
    position: absolute;
    width: 10px;
    height: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: colors.$gold-dark;

    &--top-left {
      top: -3px;
      left: -3px;
      border-radius: $border-radius 0 0 0;
    }

    &--top-right {
      top: -3px;
      right: -3px;
      border-radius: 0 $border-radius 0 0;
    }

    &--bottom-left {
      bottom: -3px;
      left: -3px;
      border-radius: 0 0 0 $border-radius;
    }

    &--bottom-right {
      bottom: -3px;
      right: -3px;
      border-radius: 0 0 $border-radius 0;
    }
  }

  &__content {
    position: relative;
    height: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: $border-radius;
    z-index: 1;
  }
}

.rounded-frame.rounded-frame--additional-borders-hidden {
  margin: 0;

  &::before, &::after {
    display: none;
  }
}
