@use '../../assets/styles/colors';
@use '../../assets/styles/mixins';

.land-card {
  width: 200px;
  background-color: transparent;
  border: none;
  border-radius: 5px;

  &__rounded-frame {
    .rounded-frame__content {
      padding: 5px;
    }
  }

  &__rectangle-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 31px;
    background-color: colors.$container-background-black-30;
  }

  &__selected-icon {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  &__media {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
  }

  &__info {
    @include mixins.label-text-small;

    &-block {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 4px;
    }

    &-block + &-block {
      margin-top: 4px;
    }

    &-label {
      color: colors.$text-white;
    }

    &-value {
      display: flex;
      align-items: center;
      column-gap: 2px;
      color: colors.$text-gold;

      &-icon {
        max-width: 12px;
      }
    }
  }
}

button.land-card:not([disabled]) {
  cursor: pointer;

  &:hover {
    @include mixins.bright-gold-glow();
  }

  &:active {
    @include mixins.bright-gold-light-glow();
  }
}

.land-card--selected {
  @include mixins.bright-gold-light-glow();
}

button.land-card[disabled] {
  .land-card__rectangle-frame::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: colors.$container-background-black-60;
  }
}

.land-card--portrait {
  .land-card {
    &__info {
      @include mixins.label-text-xxs;
    }
  }
}

.land-card--landscape {
  width: 160px;

  .land-card {
    &__info {
      @include mixins.label-text-xxs;
    }

    &__media {
      width: 80px;
      height: 80px;
    }
  }
}
