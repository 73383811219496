@use '../../../../assets/styles/colors';

.building-action {
  width: 80px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: colors.$gold-dark;

  &__external-border {
    padding: 2px;
    border-radius: 1px;
    border-width: 1px;
    border-style: solid;
    border-color: colors.$black;
    background-image: colors.$container-background-dark-purple;
  }

  &__frame {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-bottom: 2px;
  }

  &__button {
    width: 100%;
  }
}
