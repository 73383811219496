@use '../../assets/styles/mixins';
@use '../../assets/styles/colors';

.empty-slot {
  @include mixins.body-xs;
  display: flex;
  flex-direction: column;
  min-width: 160px;
  min-height: 160px;
  background-color: transparent;
  border: none;
  color: colors.$container-stroke-brown;

  &__frame {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 4px;
    flex: 1;
  }

  &__lock-media {
    margin-bottom: 6px;
  }

  &__warning {
    @include mixins.label-text-xxs;
    margin-top: 3px;
    color: colors.$state-warning;
  }
}
