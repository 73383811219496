@use '../../assets/styles/colors';
@use '../../assets/styles/mixins';

.loading-view {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-width: 686px;
  }

  &__logo {
    margin-bottom: 16px;
  }

  &__progress-bar {
    width: 100%;
    margin-bottom: 16px;
  }

  &__loading-value {
    @include mixins.button-label-small();
    color: colors.$text-gold-stroke;
  }
}
