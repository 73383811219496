@use '../../../../../../assets/styles/colors';
@use '../../../../../../assets/styles/mixins';

.level-up-character-confirmation-dialog {
  .dialog__content {
    display: flex;
    flex-direction: column;
  }

  &__frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 32px;
    margin-bottom: 16px;
  }

  &__title {
    @include mixins.body-small;
    margin-bottom: 4px;
    text-align: center;
    color: colors.$text-gold;
  }

  &__card {
    margin-bottom: 10px;
  }

  &__wombat-price {
    @include mixins.button-label-small;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    margin-top: 5px;
    text-shadow: colors.$text-shadow;

    &-media {
      max-width: 15px;
    }
  }

  &__buttons {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    column-gap: 8px;
    margin: 0 auto;
  }
}

.level-up-character-confirmation-dialog--landscape-wide {
  .level-up-character-confirmation-dialog {
    &__title {
      @include mixins.body-medium;
    }
  }
}
