@use '../../../../../../assets/styles/colors';
@use '../../../../../../assets/styles/mixins';

.unlock-slot-confirmation-dialog {
  &__frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 4px;
    padding: 8px 32px;
    margin-bottom: 14px;
  }

  &__title {
    @include mixins.body-medium;
    max-width: 200px;
    text-align: center;
    color: colors.$text-gold;
  }

  &__price {
    @include mixins.button-label-small;
    display: flex;
    align-items: center;
    column-gap: 4px;
    text-shadow: colors.$text-shadow;
  }
}
