// Text colors
$text-pink-light: #F99CFE;
$text-pink-purple: #B97ABC;
$text-gold: #FFE2B5;
$text-white: #F9F9F9;
$text-black-stroke: rgba(1, 1, 1, 0.6);
$text-purple-stroke: #2B133A;
$text-dark: #2C2821;
$text-gold-stroke: #CDA765;

// Gradient colors
$gradient-gold: linear-gradient(180deg, rgba(156, 99, 31, 0.48) 0%, rgba(114, 82, 33, 0.48) 18.69%, rgba(202, 166, 93, 0.48) 35.88%, rgba(116, 75, 23, 0.48) 57.26%, rgba(164, 121, 38, 0.48) 80.48%);;
$gradient-white-gold: linear-gradient(0deg, rgba(255, 252, 252, 0.2), rgba(255, 252, 252, 0.2)), linear-gradient(90deg, #9C631F 8.77%, #725221 30.57%, #CAA65D 50.62%, #744B17 75.56%, #A47926 102.65%);
$gradient-dark-gold: linear-gradient(90deg, #9C631F 0%, #725221 23.23%, #CAA65D 44.58%, #744B17 71.15%, #A47926 100%);
$gradient-shiny-gold: linear-gradient(180deg, rgba(255, 216, 180, 0) 0%, rgba(255, 227, 174, 0.52) 100%), linear-gradient(180deg, #9C631F -14.06%, #725221 19.33%, #CAA65D 50.03%, #744B17 88.21%, #A47926 129.69%);
$gradient-black: linear-gradient(98.69deg, rgba(0, 0, 0, 0.7) 37.74%, rgba(0, 0, 0, 0) 88.94%);
$gradient-progress-bar: linear-gradient(180deg, #6AA011 0%, #2F4B00 100%);

// Container background colors
$container-background-dark-brown: #2C2821;
$container-background-dark-purple: radial-gradient(50% 50% at 50% 50%, #622D6C 35.21%, #512A59 100%);
$container-background-black-60: rgba(0, 0, 0, 0.6);
$container-background-black-30: rgba(0, 0, 0, 0.3);

// Container stroke colors
$container-stroke-brown: #3D372E;
$container-stroke-purple: #803D8B;
$container-stroke-dark-divider: linear-gradient(90deg, rgba(61, 55, 46, 0) 0%, #3D372E 46.14%, rgba(61, 55, 46, 0) 100%);
$container-stroke-white-divider: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 46.67%, rgba(255, 255, 255, 0) 100%);

// Base colors
$black: #000000;
$black-60: rgba(1, 1, 1, 0.6);
$white: #ffffff;
$white-60: rgba(255, 255, 255, 0.6);
$gold-dark: #927A52;

// Rarities
$mythic-rarity: #FF0000;
$legendary-rarity: #FFC10A;
$epic-rarity: #BA7DF4;
$rare-rarity: #A7D5FF;
$common-rarity: #81D27A;

// Shadows
$text-shadow: 0px -0.5px 0px $black-60, 0px 1px 0px $black-60, 0px 0px 1px $black;
$container-inner-shadow: 2px 2px 1px 0px $black inset, -2px -2px 1px 0px $black inset;

// Blurs
$toast-blur: 0 6px 10px rgba(0, 0, 0, 0.25);

// States
$state-success: #48BB3E;
$state-warning: #FF5959;
