@import "https://fonts.googleapis.com/css2?family=Exo+2:wght@500;600;700;800&family=Roboto:ital,wght@0,100;1,100&display=swap";
.container {
  max-width: 1024px;
  margin: auto;
  padding: 12px;
}

.container--big {
  padding: 48px 12px;
}

.location-banner {
  width: 137px;
  text-align: center;
  color: #ffe2b5;
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  -webkit-clip-path: polygon(3% 0, 97% 0, 100% 50%, 97% 100%, 3% 100%, 0% 50%);
  clip-path: polygon(3% 0, 97% 0, 100% 50%, 97% 100%, 3% 100%, 0% 50%);
  background-color: #3d1a52;
  padding: 3px 4px 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  position: relative;
}

.location-banner__glowing {
  width: 100%;
  height: 100%;
  opacity: .2;
  background: linear-gradient(90deg, #fff0 0%, #fff 46.67%, #fff0 100%);
  position: absolute;
  top: 0;
  left: 0;
}

.location-banner__background {
  height: 100%;
  -webkit-clip-path: polygon(3% 0, 97% 0, 100% 50%, 97% 100%, 3% 100%, 0% 50%);
  clip-path: polygon(3% 0, 97% 0, 100% 50%, 97% 100%, 3% 100%, 0% 50%);
  position: absolute;
  top: 0;
}

.location-banner__background--lighter {
  width: calc(100% - 8px);
  background-color: #51226dcc;
  left: 4px;
}

.location-banner__background--lightest {
  width: calc(100% - 16px);
  background-color: #521f71;
  left: 8px;
}

.location-banner__reflection {
  width: 100%;
  height: 1px;
  opacity: .2;
  background: linear-gradient(90deg, #fff0 0%, #fff 46.67%, #fff0 100%);
  position: absolute;
  left: 0;
}

.location-banner__reflection--top {
  top: 3px;
}

.location-banner__reflection--bottom {
  bottom: 3px;
}

.location-banner__children {
  position: relative;
}

.location-banner--large {
  width: 160px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}

.location-banner--disabled {
  color: #f9f9f9;
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  background-color: #302c3366;
}

.location-banner--disabled .location-banner__background--lighter {
  background-color: #7c7481cc;
}

.location-banner--disabled .location-banner__background--lightest {
  background-color: #b1b1b199;
}

.button {
  color: #f9f9f9;
  text-transform: uppercase;
  cursor: pointer;
  background: linear-gradient(0deg, #fffcfc33, #fffcfc33), linear-gradient(90deg, #9c631f 8.77%, #725221 30.57%, #caa65d 50.62%, #744b17 75.56%, #a47926 102.65%);
  border: none;
  border-radius: 2px;
  padding: 2px;
  font-size: 14px;
  font-weight: 800;
  display: flex;
  position: relative;
}

.button:before {
  content: "";
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 1px solid #000;
  border-radius: 2px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.button:hover {
  box-shadow: 0 0 8px #ffd786, inset 0 .5px #ffffff40;
}

.button:active {
  box-shadow: 0 0 4px #ffd786, inset 0 .5px #ffffff40;
}

.button__reflection {
  width: calc(100% - 4px);
  height: 2px;
  opacity: .36;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.button__reflection:before, .button__reflection:after {
  content: "";
  width: 3px;
  height: 2px;
}

.button__reflection--upper {
  background-color: #fff;
  top: 2px;
  left: 2px;
}

.button__reflection--lower {
  background-color: #9bdb69;
  bottom: 2px;
  left: 2px;
}

.button__outer-frame {
  width: 100%;
  height: 100%;
  padding: 2px 3px;
  display: flex;
}

.button__text-container {
  width: 100%;
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  display: flex;
}

.button__icon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  display: flex;
}

.button--primary .button__outer-frame {
  background: linear-gradient(#77c22c 0%, #5f8b00 100%);
}

.button--primary .button__reflection--upper:before {
  background: linear-gradient(27deg, #76bf29 0% 50%, #0000 50% 100%);
}

.button--primary .button__reflection--upper:after {
  background: linear-gradient(-27deg, #76bf29 0% 50%, #0000 50% 100%);
}

.button--primary .button__reflection--lower:before {
  background: linear-gradient(152deg, #619004 0% 50%, #0000 50% 100%);
}

.button--primary .button__reflection--lower:after {
  background: linear-gradient(-152deg, #619004 0% 50%, #0000 50% 100%);
}

.button--primary .button__text-container {
  background: linear-gradient(#77dc54 0%, #bcdf30 100%);
  box-shadow: inset 0 0 4px #fff9;
}

.button--secondary .button__outer-frame {
  background: linear-gradient(#dfbb73 0%, #795b2f 100%);
}

.button--secondary .button__reflection--upper:before {
  background: linear-gradient(27deg, #d7b46e 0% 50%, #0000 50% 100%);
}

.button--secondary .button__reflection--upper:after {
  background: linear-gradient(-27deg, #d7b46e 0% 50%, #0000 50% 100%);
}

.button--secondary .button__reflection--lower {
  background-color: #b89144;
}

.button--secondary .button__reflection--lower:before {
  background: linear-gradient(152deg, #826436 0% 50%, #0000 50% 100%);
}

.button--secondary .button__reflection--lower:after {
  background: linear-gradient(-152deg, #826436 0% 50%, #0000 50% 100%);
}

.button--secondary .button__text-container {
  background: linear-gradient(353.37deg, #c29f57 5.04%, #a47926 94.67%);
  box-shadow: inset 0 0 4px #ffffff4d;
}

.button--tertiary .button__outer-frame {
  background: linear-gradient(#7421a7 0%, #6f188d 100%);
}

.button--tertiary .button__reflection--upper:before {
  background: linear-gradient(27deg, #7421a5 0% 50%, #0000 50% 100%);
}

.button--tertiary .button__reflection--upper:after {
  background: linear-gradient(-27deg, #7421a5 0% 50%, #0000 50% 100%);
}

.button--tertiary .button__reflection--lower {
  background-color: #260e2c;
}

.button--tertiary .button__reflection--lower:before {
  background: linear-gradient(152deg, #701990 0% 50%, #0000 50% 100%);
}

.button--tertiary .button__reflection--lower:after {
  background: linear-gradient(-152deg, #701990 0% 50%, #0000 50% 100%);
}

.button--tertiary .button__text-container {
  background: linear-gradient(#8437b3 0%, #7c2cad 49.27%, #9445c5 100%);
  box-shadow: inset 0 0 4px #ffffff26;
}

.button--ghost .button__outer-frame {
  background: linear-gradient(#dcdcdc 0%, #aaa 100%);
}

.button--ghost .button__reflection--upper:before {
  background: linear-gradient(27deg, #dbdbdb 0% 50%, #0000 50% 100%);
}

.button--ghost .button__reflection--upper:after {
  background: linear-gradient(-27deg, #dbdbdb 0% 50%, #0000 50% 100%);
}

.button--ghost .button__reflection--lower {
  background-color: #9a9a9a;
}

.button--ghost .button__reflection--lower:before {
  background: linear-gradient(152deg, #ababab 0% 50%, #0000 50% 100%);
}

.button--ghost .button__reflection--lower:after {
  background: linear-gradient(-152deg, #ababab 0% 50%, #0000 50% 100%);
}

.button--ghost .button__text-container {
  background: linear-gradient(130.98deg, #bdbdbd 19.14%, #e4e4e4 77.32%);
  box-shadow: inset 0 0 4px #ffffff26;
}

.button[disabled] {
  cursor: default;
  background: #fff9;
}

.button[disabled]:hover, .button[disabled]:active {
  box-shadow: none;
  background: #fff9;
}

.button[disabled] .button__reflection {
  opacity: 1;
}

.button[disabled] .button__reflection--upper {
  background-color: #ebebeb99;
}

.button[disabled] .button__reflection--upper:before {
  background: linear-gradient(27deg, #848484 0% 50%, #0000 50% 100%);
}

.button[disabled] .button__reflection--upper:after {
  background: linear-gradient(-27deg, #848484 0% 50%, #0000 50% 100%);
}

.button[disabled] .button__reflection--lower {
  background-color: #9a9a9a99;
}

.button[disabled] .button__reflection--lower:before {
  background: linear-gradient(152deg, #676767 0% 50%, #0000 50% 100%);
}

.button[disabled] .button__reflection--lower:after {
  background: linear-gradient(-152deg, #676767 0% 50%, #0000 50% 100%);
}

.button[disabled] .button__outer-frame {
  background: linear-gradient(#dcdcdc99 0%, #aaa9 100%);
}

.button[disabled] .button__text-container {
  background: linear-gradient(130.98deg, #bdbdbd4d 19.14%, #e4e4e44d 77.32%);
  box-shadow: inset 0 0 4px #ffffff26;
}

.button--size-small {
  text-transform: initial;
  font-size: 11px;
  font-weight: 800;
  line-height: 1;
}

@keyframes lightBackgroundMoving {
  0%, 10% {
    top: -50%;
  }

  90%, 100% {
    top: 300%;
  }
}

.rectangle-frame {
  border: 1px solid #3d372e;
  padding: 6px;
  position: relative;
  overflow: hidden;
}

.rectangle-frame__corner {
  width: 1px;
  height: 6px;
  background-color: #3d372e;
  position: absolute;
}

.rectangle-frame__corner--top-left {
  top: -1px;
  left: 1px;
  transform: rotate(45deg);
}

.rectangle-frame__corner--top-right {
  top: -1px;
  right: 1px;
  transform: rotate(-45deg);
}

.rectangle-frame__corner--bottom-left {
  bottom: -1px;
  left: 1px;
  transform: rotate(-45deg);
}

.rectangle-frame__corner--bottom-right {
  bottom: -1px;
  right: 1px;
  transform: rotate(45deg);
}

.rectangle-frame__light-animation {
  height: 70px;
  width: 200%;
  filter: blur(3px);
  transform-origin: 0 0;
  background-color: #ffffff08;
  animation: 1.8s linear infinite lightBackgroundMoving;
  position: absolute;
  bottom: 100%;
  left: -50px;
  transform: rotate(-38deg);
}

.rectangle-frame--dashed {
  border-style: dashed;
}

.rectangle-frame--purple {
  border-color: #803d8b;
}

.rectangle-frame--purple .rectangle-frame__corner {
  background-color: #803d8b;
}

.building-action {
  width: 80px;
  border: 1px solid #927a52;
  border-radius: 2px;
}

.building-action__external-border {
  background-image: radial-gradient(50% 50%, #622d6c 35.21%, #512a59 100%);
  border: 1px solid #000;
  border-radius: 1px;
  padding: 2px;
}

.building-action__frame {
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  display: flex;
}

.building-action__button {
  width: 100%;
}

.mine-building {
  max-width: 168px;
  cursor: pointer;
  background-color: #0000;
  border: none;
  position: relative;
}

.mine-building__wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.mine-building__location-banner.location-banner {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.mine-building__video {
  max-width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mine-building__modal-subtitle {
  text-align: center;
  color: #ffe2b5;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.mine-building__modal-nfts-container {
  margin-bottom: 8px;
  padding: 8px 0;
  overflow-x: auto;
}

.mine-building__modal-nfts {
  min-width: 100%;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  column-gap: 16px;
  padding: 0 5px;
  display: flex;
}

.rounded-frame {
  border: 1px solid #927a52;
  border-radius: 5px;
  margin: 2px;
  position: relative;
}

.rounded-frame:before {
  content: "";
  width: calc(100% - 16px);
  height: calc(100% + 6px);
  border: 1px solid #927a52;
  border-radius: 5px;
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translateX(-50%);
}

.rounded-frame:after {
  content: "";
  width: calc(100% + 6px);
  height: calc(100% - 16px);
  border: 1px solid #927a52;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: -3px;
  transform: translateY(-50%);
}

.rounded-frame__corner {
  width: 10px;
  height: 10px;
  border: 1px solid #927a52;
  position: absolute;
}

.rounded-frame__corner--top-left {
  border-radius: 5px 0 0;
  top: -3px;
  left: -3px;
}

.rounded-frame__corner--top-right {
  border-radius: 0 5px 0 0;
  top: -3px;
  right: -3px;
}

.rounded-frame__corner--bottom-left {
  border-radius: 0 0 0 5px;
  bottom: -3px;
  left: -3px;
}

.rounded-frame__corner--bottom-right {
  border-radius: 0 0 5px;
  bottom: -3px;
  right: -3px;
}

.rounded-frame__content {
  height: 100%;
  z-index: 1;
  background-color: #000c;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}

.rounded-frame.rounded-frame--additional-borders-hidden {
  margin: 0;
}

.rounded-frame.rounded-frame--additional-borders-hidden:before, .rounded-frame.rounded-frame--additional-borders-hidden:after {
  display: none;
}

.modal {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal__backdrop {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #01010199;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
}

.modal__frame {
  max-width: 100%;
}

.modal__frame > .rounded-frame__content {
  padding: 0;
}

.modal__close-button {
  cursor: pointer;
  z-index: 1;
  background-color: #0000;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.modal__content {
  min-width: 286px;
  max-width: 992px;
  min-height: 120px;
  max-height: 736px;
  flex-direction: column;
  display: flex;
}

.modal__content-header {
  text-align: center;
  color: #f99cfe;
  background-color: #5f2a80;
  border-bottom: 1px solid #927a52;
  border-radius: 5px 5px 0 0;
  padding: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  position: relative;
}

.modal__content-header-back-btn {
  width: 48px;
  color: #cda765;
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  cursor: pointer;
  background-color: #0000;
  border: none;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  display: flex;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.modal__content-header-back-btn-icon {
  max-width: 18px;
}

.modal__content-header--greater-padding {
  padding: 4px 63px;
}

.modal__content-main {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 20px;
  display: flex;
  overflow: auto;
}

.modal--landscape-wide .modal__content-header {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}

.land-card {
  width: 200px;
  background-color: #0000;
  border: none;
  border-radius: 5px;
}

.land-card__rounded-frame .rounded-frame__content {
  padding: 5px;
}

.land-card__rectangle-frame {
  background-color: #0000004d;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 31px;
  display: flex;
}

.land-card__selected-icon {
  position: absolute;
  top: 4px;
  right: 4px;
}

.land-card__media {
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.land-card__info {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.land-card__info-block {
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  display: flex;
}

.land-card__info-block + .land-card__info-block {
  margin-top: 4px;
}

.land-card__info-label {
  color: #f9f9f9;
}

.land-card__info-value {
  color: #ffe2b5;
  align-items: center;
  column-gap: 2px;
  display: flex;
}

.land-card__info-value-icon {
  max-width: 12px;
}

button.land-card:not([disabled]) {
  cursor: pointer;
}

button.land-card:not([disabled]):hover {
  box-shadow: 0 0 8px #ffd786, inset 0 .5px #ffffff40;
}

button.land-card:not([disabled]):active, .land-card--selected {
  box-shadow: 0 0 4px #ffd786, inset 0 .5px #ffffff40;
}

button.land-card[disabled] .land-card__rectangle-frame:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #0009;
  position: absolute;
  top: 0;
  left: 0;
}

.land-card--portrait .land-card__info {
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
}

.land-card--landscape {
  width: 160px;
}

.land-card--landscape .land-card__info {
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
}

.land-card--landscape .land-card__media {
  width: 80px;
  height: 80px;
}

.nfts-scrollable-container {
  margin-bottom: 8px;
  padding: 8px 0;
  overflow-x: auto;
}

.nfts-scrollable-container__list {
  min-width: 100%;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  column-gap: 16px;
  padding: 0 5px;
  display: flex;
}

.buttons-container {
  max-width: max-content;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  column-gap: 14px;
  margin: 0 auto;
  display: grid;
}

.buttons-container--column {
  width: 100%;
  max-width: initial;
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  row-gap: 14px;
}

.buttons-container--column > :last-child {
  order: -1;
}

.character-card {
  width: 184px;
  background-color: #0000;
  border: none;
  border-radius: 5px;
  position: relative;
}

.character-card__rounded-frame .rounded-frame__content {
  padding: 5px;
}

.character-card__rectangle-frame {
  padding: 0;
  display: flex;
}

.character-card__media-wrapper {
  align-items: flex-start;
  display: flex;
}

.character-card__selected-icon {
  position: absolute;
  top: 4px;
  right: 1px;
}

.character-card__info {
  width: calc(100% + 2px);
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  bottom: -1px;
  left: -1px;
}

.character-card__info-frame {
  background-color: #01010199;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  padding: 4px;
  display: flex;
}

.character-card__info-block {
  justify-content: center;
  align-items: center;
  column-gap: 2px;
  display: flex;
}

.character-card__info-label {
  color: #f9f9f9;
}

.character-card__info-icon path {
  fill: #f9f9f9;
}

.character-card__info-value {
  color: #ffe2b5;
}

button.character-card:not([disabled]) {
  cursor: pointer;
}

button.character-card:not([disabled]):hover {
  box-shadow: 0 0 8px #ffd786, inset 0 .5px #ffffff40;
}

button.character-card:not([disabled]):active, .character-card--selected {
  box-shadow: 0 0 4px #ffd786, inset 0 .5px #ffffff40;
}

.character-card--inactive .character-card__media-wrapper {
  height: 172px;
}

.character-card--portrait .character-card__info {
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
}

.character-card--landscape {
  width: 132px;
}

.character-card--landscape .character-card__info {
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
}

.character-card--inactive.character-card--landscape .character-card__media-wrapper {
  height: 120px;
}

.recall-character-modal__mine {
  background-color: #0000;
  border: none;
}

.recall-character-modal__mine-rounded-frame .rounded-frame__content {
  column-gap: 2px;
  padding: 5px;
  display: flex;
}

.recall-character-modal__mine-land .land-card__rectangle-frame {
  height: 100%;
}

.recall-character-modal__button {
  align-self: center;
}

.recall-character-modal__button--portrait {
  align-self: initial;
}

.land-with-character-card {
  background-color: #0000;
  border: none;
  border-radius: 5px;
}

.land-with-character-card__rounded-frame .rounded-frame__content {
  column-gap: 2px;
  padding: 5px;
  display: flex;
}

.land-with-character-card__land-card {
  width: 90px;
}

.land-with-character-card__land-card .land-card__rectangle-frame {
  height: 100%;
  padding: 14px;
}

.land-with-character-card__land-card .land-card__rectangle-frame .land-card__media {
  width: initial;
  height: initial;
}

.land-with-character-card__character-card, .land-with-character-card__character-card.character-card--inactive {
  width: 113px;
}

.land-with-character-card__character-card .character-card__media-wrapper, .land-with-character-card__character-card.character-card--inactive .character-card__media-wrapper {
  height: initial;
}

.land-with-character-card:not([disabled]) {
  cursor: pointer;
}

.land-with-character-card:not([disabled]):hover, .land-with-character-card:not([disabled]):active {
  box-shadow: 0 0 8px #ffd786, inset 0 .5px #ffffff40;
}

.land-with-character-card--selected .character-card--selected {
  box-shadow: none;
}

.land-with-character-card--landscape-wide-mode .land-with-character-card__land-card {
  width: 100px;
}

.land-with-character-card--landscape-wide-mode .land-with-character-card__character-card, .land-with-character-card--landscape-wide-mode .land-with-character-card__character-card.character-card--inactive {
  width: 130px;
}

.close-mine-modal__button {
  margin: 0 auto;
}

.claimable-materials {
  position: relative;
}

.claimable-materials__button {
  background-color: #0000;
  border: none;
}

.claimable-materials__button:not([disabled]) {
  cursor: pointer;
}

.claimable-materials__button[disabled] {
  opacity: .7;
}

.loading-spinner {
  max-width: 32px;
}

.building-actions__container {
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2px;
  display: grid;
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
}

.dojo-building {
  max-width: 222px;
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.dojo-building__wrapper {
  margin-top: 94px;
  position: relative;
}

.dojo-building__location-banner {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.dojo-building__modal-subtitle {
  text-align: center;
  color: #ffe2b5;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.dojo-building__modal-subtitle--landscape-wide {
  font-size: 16px;
  font-weight: 500;
}

.empty-slot {
  min-width: 160px;
  min-height: 160px;
  color: #3d372e;
  background-color: #0000;
  border: none;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.empty-slot__frame {
  text-align: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
}

.empty-slot__lock-media {
  margin-bottom: 6px;
}

.empty-slot__warning {
  color: #ff5959;
  margin-top: 3px;
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
}

.dialog {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.dialog__backdrop {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #01010199;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
}

.dialog__frame > .rounded-frame__content {
  background-color: #2c2821;
  padding: 4px 4px 16px;
}

.unlock-slot-confirmation-dialog__frame {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
  margin-bottom: 14px;
  padding: 8px 32px;
  display: flex;
}

.unlock-slot-confirmation-dialog__title {
  max-width: 200px;
  text-align: center;
  color: #ffe2b5;
  font-size: 16px;
  font-weight: 500;
}

.unlock-slot-confirmation-dialog__price {
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  align-items: center;
  column-gap: 4px;
  font-size: 14px;
  font-weight: 800;
  display: flex;
}

.toast {
  text-align: center;
  color: #f9f9f9;
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  filter: drop-shadow(0 6px 10px #00000040);
  background-image: linear-gradient(90deg, #caa65d00 0%, #caa65de0 49.36%, #caa65d00 100%);
  padding: 3px 16px 4px;
  font-size: 14px;
  font-weight: 800;
  display: inline-block;
  position: relative;
}

.toast:before, .toast:after {
  content: "";
  width: calc(100% - 32px);
  height: 1px;
  opacity: .2;
  background-image: linear-gradient(90deg, #fff0 0%, #fff 46.67%, #fff0 100%);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.toast:before {
  top: 1px;
}

.toast:after {
  bottom: 1px;
}

.toast--warning {
  background-image: linear-gradient(90deg, #ff595900 3.96%, #ff59599c 47.4%, #ff595900 94.9%);
}

.toast--large {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
}

.toast-group {
  flex-direction: column-reverse;
  row-gap: 10px;
  display: flex;
  position: fixed;
  bottom: 12px;
  right: 12px;
}

.level-up-character-confirmation-dialog .dialog__content {
  flex-direction: column;
  display: flex;
}

.level-up-character-confirmation-dialog__frame {
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px 32px;
  display: flex;
}

.level-up-character-confirmation-dialog__title {
  text-align: center;
  color: #ffe2b5;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
}

.level-up-character-confirmation-dialog__card {
  margin-bottom: 10px;
}

.level-up-character-confirmation-dialog__wombat-price {
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 800;
  display: flex;
}

.level-up-character-confirmation-dialog__wombat-price-media {
  max-width: 15px;
}

.level-up-character-confirmation-dialog__buttons {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  column-gap: 8px;
  margin: 0 auto;
  display: grid;
}

.level-up-character-confirmation-dialog--landscape-wide .level-up-character-confirmation-dialog__title {
  font-size: 16px;
  font-weight: 500;
}

.level-up-character-success-dialog__frame {
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px 22px;
  display: flex;
}

.level-up-character-success-dialog__title {
  color: #ffe2b5;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 800;
}

.level-up-character-success-dialog__wrapper {
  align-items: center;
  column-gap: 10px;
  display: flex;
}

.level-up-character-success-dialog__asset {
  position: relative;
}

.level-up-character-success-dialog__asset-animated-media {
  z-index: 2;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.level-up-character-success-dialog__boost {
  width: 162px;
  color: #ffe2b5;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image: linear-gradient(90deg, #3d372e00 0%, #3d372e 46.14%, #3d372e00 100%) 1;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.level-up-character-success-dialog__boost-label, .level-up-character-success-dialog__boost-value {
  align-items: center;
  column-gap: 4px;
  display: flex;
}

.level-up-character-success-dialog__boost-value-added {
  color: #48bb3e;
}

.level-up-character-success-dialog__button {
  margin: 0 auto;
}

.level-up-character-success-dialog--landscape-wide .level-up-character-success-dialog__title {
  font-size: 20px;
  font-weight: 800;
}

.level-up-character-success-dialog--portrait .level-up-character-success-dialog__wrapper {
  flex-direction: column;
  row-gap: 8px;
}

.progress-bar {
  border-style: solid;
  border-width: 1px 0;
  border-image: linear-gradient(90deg, #9c631f 0%, #725221 23.23%, #caa65d 44.58%, #744b17 71.15%, #a47926 100%) 1;
  margin: 0 4px;
  padding: 1px;
  position: relative;
}

.progress-bar:before, .progress-bar:after {
  content: "";
  width: 6px;
  height: 6px;
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(90deg, #9c631f 0%, #725221 23.23%, #caa65d 44.58%, #744b17 71.15%, #a47926 100%) 1;
  position: absolute;
  top: 50%;
}

.progress-bar:before {
  border-top-width: 0;
  border-right-width: 0;
  left: 0;
  transform: translate(-50%, -50%)rotate(45deg);
}

.progress-bar:after {
  border-bottom-width: 0;
  border-left-width: 0;
  right: 0;
  transform: translate(50%, -50%)rotate(45deg);
}

.progress-bar__current-position {
  max-width: 100%;
  height: 4px;
  background-image: linear-gradient(#6aa011 0%, #2f4b00 100%);
  transition: width .3s;
  position: relative;
}

.progress-bar__current-position:before {
  filter: blur(2px);
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#a7ff17 0%, #7cc800 100%);
  position: absolute;
  top: 0;
  left: 0;
}

.staked-character-in-dojo__progress-xp-title {
  text-align: center;
  color: #ffe2b5;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.recall-character-from-dojo-dialog .dialog__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.recall-character-from-dojo-dialog__frame {
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px 16px;
  display: flex;
}

.recall-character-from-dojo-dialog__title {
  color: #ffe2b5;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
}

.recall-character-from-dojo-dialog__card {
  margin-bottom: 4px;
}

.recall-character-from-dojo-dialog__description {
  max-width: 240px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.recall-character-from-dojo-dialog--landscape-wide .recall-character-from-dojo-dialog__title {
  font-size: 16px;
  font-weight: 500;
}

.recall-character-from-dojo-dialog--landscape-wide .recall-character-from-dojo-dialog__description {
  font-size: 14px;
  font-weight: 500;
}

.staked-character-in-dojo {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.staked-character-in-dojo__card {
  margin-bottom: 10px;
  position: relative;
}

.staked-character-in-dojo__card-scored-xp.toast {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.staked-character-in-dojo__progress {
  width: 100%;
  margin-bottom: 15px;
}

.staked-character-in-dojo__boosts {
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  margin-bottom: 14px;
  display: flex;
}

.staked-character-in-dojo__boost {
  align-items: center;
  column-gap: 4px;
  display: flex;
}

.staked-character-in-dojo__boost-label {
  color: #ffe2b5;
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.staked-character-in-dojo__boost-value {
  color: #48bb3e;
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  font-size: 14px;
  font-weight: 800;
}

.staked-character-in-dojo__buttons {
  width: 100%;
  flex-direction: column;
  row-gap: 2px;
  display: flex;
}

.sensei-card {
  width: 184px;
  background-color: #0000;
  border: none;
  border-radius: 5px;
  position: relative;
}

.sensei-card__rounded-frame .rounded-frame__content {
  padding: 4px;
}

.sensei-card__rectangle-frame {
  padding: 0;
  display: flex;
}

.sensei-card__media {
  width: 210px;
  max-width: initial;
  transform: translateX(-21px);
}

.sensei-card__selected-icon {
  position: absolute;
  top: 4px;
  right: 4px;
}

.sensei-card__xp-boost {
  width: calc(100% + 2px);
  color: #f9f9f9;
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  background-color: #01010199;
  justify-content: center;
  align-items: center;
  column-gap: 2px;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  display: flex;
  position: absolute;
  bottom: -1px;
  left: -1px;
}

.sensei-card__xp-boost-value {
  color: #ffe2b5;
}

.sensei-card--landscape {
  width: 132px;
}

.sensei-card--landscape .sensei-card__media {
  width: 158px;
}

.sensei-card--landscape-wide .sensei-card__xp-boost {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

button.sensei-card:not([disabled]) {
  cursor: pointer;
}

button.sensei-card:not([disabled]):hover {
  box-shadow: 0 0 8px #ffd786, inset 0 .5px #ffffff40;
}

button.sensei-card:not([disabled]):active, .sensei-card--selected {
  box-shadow: 0 0 4px #ffd786, inset 0 .5px #ffffff40;
}

.dojo-modal__container {
  grid-template-columns: 200px auto;
  align-items: center;
  column-gap: 8px;
  display: grid;
}

.dojo-modal__characters-and-slots {
  overflow: hidden;
}

.dojo-modal__characters-and-slots-wrapper {
  background-color: #01010199;
  border: 1px solid #3d372e;
  border-radius: 8px;
  padding: 0 3px;
  box-shadow: inset 2px 2px 1px #000, inset -2px -2px 1px #000;
}

.dojo-modal__characters {
  margin-bottom: 4px;
  position: relative;
}

.dojo-modal__characters-btn {
  width: max-content;
  align-self: center;
}

.dojo-modal__characters-no-nfts {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  padding: 30px 10px;
  display: flex;
}

.dojo-modal__characters-no-nfts-label {
  color: #ffe2b5;
  font-size: 12px;
  font-weight: 500;
}

.dojo-modal__characters-toast.toast {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dojo-modal__slots {
  column-gap: 8px;
  display: flex;
}

.dojo-modal__slots-unlocked-slot-button {
  min-width: 110px;
}

.dojo-modal--portrait .modal__content-main {
  justify-content: flex-start;
}

.dojo-modal--portrait .dojo-modal__container {
  align-items: initial;
  grid-template-columns: initial;
  gap: 8px 0;
}

.dojo-modal--portrait .dojo-modal__media {
  max-width: 120px;
  margin: 0 auto;
}

.dojo-modal--portrait .dojo-modal__characters {
  margin-bottom: 8px;
}

.staked-sensei__card {
  margin: 10px auto 18px;
}

.staked-sensei__button {
  margin: 0 auto;
}

.recall-sensei-dialog__rectangle-frame {
  margin-bottom: 14px;
  padding: 16px;
}

.main-view {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.main-view__wrapper {
  min-height: 100vh;
  height: 100%;
  background-image: url("map-large.98d23282.png");
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
}

.main-view__dojo-building {
  margin-bottom: 90px;
  transform: translateX(62px);
}

.main-view__dojo-building--portrait {
  transform: translateX(0);
}

.material-amount {
  width: 113px;
  color: #f9f9f9;
  border-style: solid;
  border-width: 1px 0;
  border-image: linear-gradient(90deg, #9c631f 0%, #725221 23.23%, #caa65d 44.58%, #744b17 71.15%, #a47926 100%) 1;
  margin-left: 12px;
  padding: 1px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  position: relative;
}

.material-amount:before, .material-amount:after {
  content: "";
  width: 1px;
  height: 11.5px;
  background: linear-gradient(90deg, #9c631f 0%, #725221 23.23%, #caa65d 44.58%, #744b17 71.15%, #a47926 100%);
  position: absolute;
  right: -2px;
}

.material-amount:before {
  top: -1px;
  transform: rotate(-18deg);
}

.material-amount:after {
  bottom: -1px;
  transform: rotate(18deg);
}

.material-amount__frame {
  text-align: right;
  background-color: #0009;
  border: 1px solid #00f;
  border-image: linear-gradient(90deg, #9c631f 0%, #725221 23.23%, #caa65d 44.58%, #744b17 71.15%, #a47926 100%) 1;
  padding: 2px 4px 2px 0;
}

.material-amount__media {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.material-amount--size-small {
  width: 83px;
}

.material-amount--size-large {
  width: 126px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

.material-amount--size-large:before, .material-amount--size-large:after {
  height: 13.5px;
}

.material-amount--size-large .material-amount__media {
  max-width: 32px;
}

.components-view__title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}

.components-view__subtitle {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

.components-view__container {
  align-items: flex-start;
  column-gap: 20px;
  display: flex;
}

.components-view__tab-button + .components-view__tab-button {
  margin-top: 10px;
}

.components-view__tab-content {
  flex: 1;
}

.components-view__component {
  vertical-align: middle;
  margin-bottom: 20px;
  margin-right: 20px;
  display: inline-block;
}

.components-view__component-title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.rarity-badge {
  text-align: center;
  color: #f9f9f9;
  text-shadow: 0 -.5px #01010199, 0 1px #01010199, 0 0 1px #000;
  padding: 1px 4px;
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
}

.rarity-badge--common {
  background-image: linear-gradient(90deg, #81d27a00 3.96%, #81d27a 47.4%, #81d27a00 94.9%);
}

.rarity-badge--rare {
  background-image: linear-gradient(90deg, #a7d5ff00 3.96%, #a7d5ff 47.4%, #a7d5ff00 94.9%);
}

.rarity-badge--epic {
  background-image: linear-gradient(90deg, #ba7df400 3.96%, #ba7df4 47.4%, #ba7df400 94.9%);
}

.rarity-badge--legendary {
  background-image: linear-gradient(90deg, #ffc10a00 3.96%, #ffc10a 47.4%, #ffc10a00 94.9%);
}

.rarity-badge--mythic {
  background-image: linear-gradient(90deg, #f000 3.96%, red 47.4%, #f000 94.9%);
}

.login-view {
  height: 100vh;
  padding: 14px;
}

.login-view__rounded-frame, .login-view__rectangle-frame {
  height: 100%;
}

.login-view__container {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-view__logo {
  max-width: 122px;
  margin-bottom: 16px;
}

.login-view__title {
  color: #cda765;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

.login-view__buttons {
  grid-template-rows: 1fr 1fr;
  row-gap: 6px;
  display: grid;
}

.loading-view__container {
  height: 100vh;
  max-width: 686px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.loading-view__logo {
  margin-bottom: 16px;
}

.loading-view__progress-bar {
  width: 100%;
  margin-bottom: 16px;
}

.loading-view__loading-value {
  color: #cda765;
  font-size: 14px;
  font-weight: 800;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: #f9f9f9;
  background-color: #000;
  font-family: "Exo 2", sans-serif;
  font-size: 16px;
  line-height: 1.2;
}

input, input::placeholder, button {
  font-family: "Exo 2", sans-serif;
}

img, svg {
  max-width: 100%;
  height: auto;
}

/*# sourceMappingURL=index.bc62c739.css.map */
