@use '../../../../assets/styles/colors';
@use '../../../../assets/styles/mixins';

$maxWidth: 222px;

.dojo-building {
  max-width: $maxWidth;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &__wrapper {
    position: relative;
    margin-top: 94px;
  }

  &__location-banner {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__modal-subtitle {
    @include mixins.body-small;
    margin-bottom: 8px;
    text-align: center;
    color: colors.$text-gold;

    &--landscape-wide {
      @include mixins.body-medium;
    }
  }
}
