@use '../../assets/styles/mixins';
@use '../../assets/styles/colors';

.material-amount {
  position: relative;
  @include mixins.label-text-xs();
  width: 113px;
  margin-left: 12px;
  padding: 1px 0;
  color: colors.$text-white;
  border-style: solid;
  border-image: colors.$gradient-dark-gold 1;
  border-width: 1px 0;

  &::before, &::after {
    content: '';
    position: absolute;
    right: -2px;
    width: 1px;
    height: 11.5px;
    background: colors.$gradient-dark-gold;
  }

  &::before {
    top: -1px;
    transform: rotate(-18deg);
  }

  &::after {
    bottom: -1px;
    transform: rotate(18deg);
  }

  &__frame {
    text-align: right;
    padding: 2px 4px 2px 0;
    border-width: 1px;
    border-style: solid;
    border-color: blue;
    background-color: colors.$container-background-black-60;
    border-image: colors.$gradient-dark-gold 1;
  }

  &__media {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 0;
    max-width: 24px;
  }
}

.material-amount--size-small {
  width: 83px;
}

.material-amount--size-large {
  @include mixins.label-text-medium();
  width: 126px;
  margin-left: 16px;

  &::before, &::after {
    height: 13.5px;
  }

  .material-amount__media {
    max-width: 32px;
  }
}
