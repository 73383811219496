@use '../../../../../../assets/styles/colors';
@use '../../../../../../assets/styles/mixins';

.recall-character-from-dojo-dialog {
  .dialog__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    padding: 8px 16px;
  }

  &__title {
    @include mixins.body-small;
    margin-bottom: 4px;
    color: colors.$text-gold;
  }

  &__card {
    margin-bottom: 4px;
  }

  &__description {
    @include mixins.body-xs;
    max-width: 240px;
    text-align: center;
  }
}

.recall-character-from-dojo-dialog--landscape-wide {
  .recall-character-from-dojo-dialog {
    &__title {
      @include mixins.body-medium;
    }

    &__description {
      @include mixins.body-small;
    }
  }
}
