@use 'src/assets/styles/colors';
@use 'src/assets/styles/mixins';

.level-up-character-success-dialog {
  &__frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    padding: 8px 22px;
  }

  &__title {
    @include mixins.button-label-medium;
    margin-bottom: 4px;
    color: colors.$text-gold;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__asset {
    position: relative;

    &-animated-media {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  &__boost {
    @include mixins.body-xs;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 162px;
    padding: 4px 0;
    color: colors.$text-gold;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-image: colors.$container-stroke-dark-divider 1;

    &-label {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }

    &-value {
      display: flex;
      align-items: center;
      column-gap: 4px;

      &-added {
        color: colors.$state-success;
      }
    }
  }

  &__button {
    margin: 0 auto;
  }
}

.level-up-character-success-dialog--landscape-wide {
  .level-up-character-success-dialog {
    &__title {
      @include mixins.button-label-large;
    }
  }
}

.level-up-character-success-dialog--portrait {
  .level-up-character-success-dialog {
    &__wrapper {
      flex-direction: column;
      row-gap: 8px;
    }
  }
}
