.recall-character-modal {
  &__mine {
    background-color: transparent;
    border: none;

    &-rounded-frame {
      .rounded-frame__content {
        display: flex;
        column-gap: 2px;
        padding: 5px;
      }
    }

    &-land {
      .land-card__rectangle-frame {
        height: 100%;
      }
    }
  }

  &__button {
    align-self: center;

    &--portrait {
      align-self: initial;
    }
  }
}
