@use '../../assets/styles/colors';

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: colors.$black-60;
    border: none;
    cursor: pointer;
  }

  &__frame {
    & > .rounded-frame__content {
      padding: 4px 4px 16px;
      background-color: colors.$container-background-dark-brown;
    }
  }
}
