@use '../../assets/styles/colors';
@use '../../assets/styles/mixins';

.sensei-card {
  position: relative;
  width: 184px;
  background-color: transparent;
  border-radius: 5px;
  border: none;

  &__rounded-frame {
    .rounded-frame__content {
      padding: 4px;
    }
  }

  &__rectangle-frame {
    display: flex;
    padding: 0;
  }

  &__media {
    width: 210px;
    max-width: initial;
    transform: translateX(-21px)
  }

  &__selected-icon {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  &__xp-boost {
    @include mixins.label-text-xs;
    position: absolute;
    bottom: -1px;
    left: -1px;
    width: calc(100% + 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2px;
    padding: 4px;
    background-color: colors.$black-60;
    color: colors.$text-white;
    text-shadow: colors.$text-shadow;

    &-value {
      color: colors.$text-gold;
    }
  }
}

.sensei-card--landscape {
  width: 132px;

  .sensei-card__media {
    width: 158px;
  }
}

.sensei-card--landscape-wide {
  .sensei-card__xp-boost {
    @include mixins.label-text-medium;
  }
}

button.sensei-card:not([disabled]) {
  cursor: pointer;

  &:hover {
    @include mixins.bright-gold-glow;
  }

  &:active {
    @include mixins.bright-gold-light-glow;
  }
}

.sensei-card--selected {
  @include mixins.bright-gold-light-glow;
}
