@use '../../../../../../assets/styles/colors';
@use '../../../../../../assets/styles/mixins';

.staked-character-in-dojo {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__card {
    position: relative;
    margin-bottom: 10px;

    &-scored-xp.toast {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  &__progress {
    margin-bottom: 15px;
    width: 100%;
  }

  &__boosts {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
    margin-bottom: 14px;
  }

  &__boost {
    display: flex;
    align-items: center;
    column-gap: 4px;

    &-label {
      @include mixins.label-text-small;
      color: colors.$text-gold;
      text-shadow: colors.$text-shadow;
    }

    &-value {
      @include mixins.button-label-small;
      color: colors.$state-success;
      text-shadow: colors.$text-shadow;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    width: 100%;
  }
}
