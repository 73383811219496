@use '../../assets/styles/colors';
@use '../../assets/styles/mixins';

$backButtonWidth: 48px;
$backButtonPaddingLeft: 15px;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: colors.$black-60;
    border: none;
    cursor: pointer;
  }

  &__frame {
    max-width: 100%;

    & > .rounded-frame__content {
      padding: 0;
    }
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-width: 286px;
    max-width: 992px;
    min-height: 120px;
    max-height: 736px;

    &-header {
      @include mixins.label-text-medium();
      position: relative;
      padding: 4px;
      text-align: center;
      color: colors.$text-pink-light;
      background-color: #5F2A80;
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid colors.$gold-dark;

      &-back-btn {
        @include mixins.label-text-xs;
        position: absolute;
        top: 50%;
        left: $backButtonPaddingLeft;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: $backButtonWidth;
        color: colors.$text-gold-stroke;
        text-shadow: colors.$text-shadow;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &-icon {
          max-width: 18px;
        }
      }
    }

    &-header--greater-padding {
      padding: 4px ($backButtonWidth + $backButtonPaddingLeft);
    }

    &-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      padding: 20px;
      overflow: auto;
    }
  }
}

.modal--landscape-wide {
  .modal {
    &__content-header {
      @include mixins.label-text-large;
    }
  }
}
