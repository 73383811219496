.claimable-materials {
  &__button {
    border: none;
    background-color: transparent;

    &:not([disabled]) {
      cursor: pointer;
    }

    &[disabled] {
      opacity: 0.7;
    }
  }
}
