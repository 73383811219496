@use './colors';

// Glow effects
@mixin progress-glow {
  filter: blur(2px);
}
@mixin bright-gold-glow {
  box-shadow: 0 0 8px #FFD786, inset 0 0.5px 0 rgba(255, 255, 255, 0.25);
}
@mixin bright-gold-light-glow {
  box-shadow: 0 0 4px #FFD786, inset 0 0.5px 0 rgba(255, 255, 255, 0.25);
}
@mixin progress-bar-glow {
  background-image: linear-gradient(180deg, #A7FF17 0%, #7CC800 100%);
  filter: blur(2px);
}

// Shadows
@mixin card-inner-shadow {
  background-color: #D9D9D9;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.25);
}
@mixin text-shadow {
  background-color: #D9D9D9;
  box-shadow: colors.$text-shadow;
}
@mixin button-shadow {
  background: #D9D9D9;
  box-shadow: 0 0 10px #CAA65D, inset 0 0.5px 0 rgba(255, 255, 255, 0.25);
}

// Label texts
@mixin label-text-large {
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
}
@mixin label-text-medium {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
}
@mixin label-text-small {
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
}
@mixin label-text-xs {
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
}
@mixin label-text-xxs {
  font-weight: 600;
  font-size: 11px;
  line-height: 1;
}

// Body texts
@mixin body-medium {
  font-weight: 500;
  font-size: 16px;
}
@mixin body-small {
  font-weight: 500;
  font-size: 14px;
}
@mixin body-xs {
  font-weight: 500;
  font-size: 12px;
}

// Button labels
@mixin button-label-large {
  font-weight: 800;
  font-size: 20px;
}
@mixin button-label-medium {
  font-weight: 800;
  font-size: 16px;
}
@mixin button-label-small {
  font-weight: 800;
  font-size: 14px;
}
@mixin button-label-xs {
  font-weight: 800;
  font-size: 11px;
  line-height: 1;
}
