@use '../../assets/styles/mixins';

.components-view {
  &__title {
    @include mixins.label-text-large();
    margin-bottom: 20px;
  }

  &__subtitle {
    @include mixins.label-text-medium();
    margin-bottom: 10px;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
  }

  &__tab-button + &__tab-button {
    margin-top: 10px;
  }

  &__tab-content {
    flex: 1;
  }

  &__component {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  &__component-title {
    @include mixins.label-text-small();
    margin-bottom: 10px;
  }
}
