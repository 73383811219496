@use '../../assets/styles/mixins';

.land-with-character-card {
  background-color: transparent;
  border: none;
  border-radius: 5px;

  &__rounded-frame {
    .rounded-frame__content {
      display: flex;
      column-gap: 2px;
      padding: 5px;
    }
  }

  &__land-card {
    width: 90px;

    .land-card__rectangle-frame {
      height: 100%;
      padding: 14px;

      .land-card__media {
        width: initial;
        height: initial;
      }
    }
  }

  &__character-card,
  &__character-card.character-card--inactive {
    width: 113px;

    .character-card__media-wrapper {
      height: initial;
    }
  }
}

.land-with-character-card:not([disabled]) {
  cursor: pointer;

  &:hover {
    @include mixins.bright-gold-glow;
  }

  &:active {
    @include mixins.bright-gold-glow;
  }
}

.land-with-character-card--selected {
  .character-card--selected {
    box-shadow: none;
  }
}

.land-with-character-card--landscape-wide-mode {
  .land-with-character-card {
    &__land-card {
      width: 100px;
    }

    &__character-card,
    &__character-card.character-card--inactive {
      width: 130px;
    }
  }
}
